<template>
    <section data-aos="fade-up" data-aos-duration="1000">
        <div class="container slider-box">
            <div class="slider-container">
                <div class="slider-control left inactive"></div>
                <div class="slider-control right"></div>
                <ul class="slider-pagi"></ul>
                <div class="slider">
                    <div class="slide slide-0 active">
                        <div class="slide__bg"></div>
                        <div class="slide__content">
                            <svg class="slide__overlay" viewBox="0 0 720 405" preserveAspectRatio="xMaxYMax slice">
                                <path class="slide__overlay-path" d="M0,0 150,0 500,405 0,405"/>
                            </svg>
                            <div class="slide__text">
                                <h3 class="slide__text-heading">Custom Software Development Backed By Our Service
                                    Guarantee</h3>
                                <p class="slide__text-desc">We deliver high quality web and mobile applications. ORT
                                    guarantees completion of the product and will work for free to keep you
                                    satisfied!</p>
                                <a href="projects/" class="btn-blue read-more-slider">Our Work</a>
                            </div>
                        </div>
                    </div>
                    <div class="slide slide-1 ">
                        <div class="slide__bg"></div>
                        <div class="slide__content">
                            <svg class="slide__overlay" viewBox="0 0 720 405" preserveAspectRatio="xMaxYMax slice">
                                <path class="slide__overlay-path read-more-slider" d="M0,0 150,0 500,405 0,405"/>
                            </svg>
                            <div class="slide__text">
                                <h3 class="slide__text-heading">Technical Expertise to Non-Profits</h3>
                                <p class="slide__text-desc">We are dedicated to helping non-profit organizations achieve
                                    their goals by donating hours as a trusted technology resource through enterprise
                                    sponsorship or providing services within budget of the organization.</p>
                                <a href="#whatwedo" class="btn-blue read-more-slider">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div class="slide slide-2">
                        <div class="slide__bg"></div>
                        <div class="slide__content">
                            <svg class="slide__overlay" viewBox="0 0 720 405" preserveAspectRatio="xMaxYMax slice">
                                <path class="slide__overlay-path" d="M0,0 150,0 500,405 0,405"/>
                            </svg>
                            <div class="slide__text">
                                <h3 class="slide__text-heading">Turning Legacy Into Legendary</h3>
                                <p class="slide__text-desc">We specialize in rebuilding legacy applications to meet
                                    today's standards while assisting in the maintenance of the current system. Our
                                    services include adding mobile capabilities for both iOS and Android platforms to
                                    your existing systems using a streamlined workflow.</p>
                                <a href="projects/" class="btn-blue read-more-slider">See Projects</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="home" class="container inside-wrapper ">
            <div class="row about">
                <div class="col-md-6 col-heading border-right min-height">
                    <a id="whatwedo"></a>
                    <h2 class="heading wow">Why Ocean<br>Ring Technologies?</h2>
                    <ul class="whyORT">
                        <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Customer Service Guarantee Policy</span></li>
                        <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Technology Company for Social Good</span></li>
                        <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>History of Quality Products and Service</span></li>
                        <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Each Enterprise Project Helps a Non-Profit</span></li>
                        <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Mobile Development for Both iOS and Android</span></li>
                        <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Custom Solutions and Insightful Data Analysis</span></li>
                        <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Experience with ADA and HIPAA Compliancy</span></li>
                    </ul>
                    <br/><br/>
                    <router-link class="btn-blue" to="/work">Our Work</router-link>
                </div>
                <div class="col-md-6 back-image min-height hidden-sm hidden-xs back-image-1">
                    &nbsp;
                </div>
            </div>
            <div class="about row">
                <div class="col-md-6 back-image min-height hidden-sm hidden-xs back-image-2">
                    &nbsp;
                </div>

                <div class="col-md-6 col-heading border-left min-height">
                    <h2 class="heading wow ">Our Values</h2>
                    <p class="text-dark wow text-justify">
                    <h3 class="text-dark">
                        To help non-profit and socially good organizations achieve their goals by donating technical
                        services as a trusted
                        technology resource through enterprise sponsorship.
                    </h3>
                    <br/>
                    <ul class="whyORT">
                        <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Support Organizations Striving for Positive Change</span>
                        </li>
                        <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Treat All People as Equals Regardless of Gender, Race or Orientation</span>
                        </li>
                        <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Projects Need to Lessen The Footprint Upon the Planet</span>
                        </li>
                        <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Assist in Empowering the Youth of Today</span>
                        </li>
                    </ul>
                    </p>
                    <br/>
                </div>
            </div>
        </div>

        <div class="row back-grey" id="technologies">

            <div class="col-sm-12 boxes-wrapper">
                <div class="col-sm-4 back-box1">
                    <h2 class="text-dark text-bold">Small Business</h2>
                    <p class="text-dark subheading text-justify">
                        We try to accommodate every business and learn about their workflow. Technology today allows for
                        businesses to be even more efficient.
                        <br>
                    </p>
                </div>
                <div class="col-sm-4 back-box2">
                    <h2 class="text-white text-bold">Non-Profits</h2>
                    <p class="text-white subheading text-justify">
                        ORT realizes that the budget doesn't always allow for the luxury option, so we work within the
                        budget you have available.
                        <br>
                    </p>
                </div>
                <div class="col-sm-4 back-box3">
                    <h2 class="text-white text-bold">Enterprise</h2>
                    <p class="text-white subheading text-justify">
                        The technological needs of an enterprise company is often expensive and complex. Allow ORT to
                        help keep costs down with team augmentation.
                        <br>
                    </p>
                </div>
            </div>


            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-4 col-technologies boxes-tech">
                        <div class="col-sm-12 nopadding"><h3 class="text-dark text-bold marginBottom">Choose your
                            technology</h3></div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-6 text-center min-height   back-white"><i
                                    class="devicon-dot-net-plain"></i>
                                <p class=""><br>.NET</p></div>
                            <div class="col-sm-6 col-xs-6 text-center min-height border-left   back-white"><i
                                    class="devicon-javascript-plain"></i>
                                <p class=""><br>JAVASCRIPT</p></div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-6 text-center min-height  border-top  back-white"><i
                                    class="devicon-angularjs-plain"></i>
                                <p class=""><br>ANGULAR JS</p></div>
                            <div class="col-sm-6 col-xs-6 text-center min-height border-left border-top  back-white"><i
                                    class="devicon-mysql-plain"></i>
                                <p class=""><br>MYSQL</p></div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-6 text-center min-height  border-top  back-white custom-border3">
                                <i class="devicon-react-original"></i>
                                <p class=""><br>REACT</p></div>
                            <div class="col-sm-6 col-xs-6 text-center min-height border-left border-top  back-white"><i
                                    class="devicon-git-plain"></i>
                                <p class=""><br>GIT</p></div>
                        </div>


                    </div>


                    <div class="col-sm-8">
                        <div class="col-sm-12 nopadding"><h3 class="text-dark text-bold contact-tilte">Get a Free
                            Quote</h3></div>
                        <div class="col-technologies boxes-tech contact-form">
                            <p class="text-dark wow text-dark subheading text-justify">
                                Whether you are looking for a prototype or an enterprise solution, we always look to
                                provide our clients with professional and flexible services.
                                Our team specializes in working with clients to meet their software solution needs as
                                well as augment development teams to meet tight deadlines.
                                <br><br>
                            </p>
                            <form id="contact-form" method="post">
                                <div class="messages"></div>
                                <div class="controls">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input id="form_name" type="text" name="name" class="form-control"
                                                       required data-error="Name is required.">
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Phone</label>
                                                <input id="form_phone" type="text" name="phone" class="form-control">
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input id="form_email" type="email" name="email" class="form-control"
                                                       required="required" data-error="Valid email is required.">
                                                <input id="form_location" type="text" name="location"
                                                       class="form-control hide">
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Message</label>
                                                <textarea id="form_message" name="message" class="form-control" rows="2"
                                                          required data-error="Please,leave us a message."></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn-blue">Send <i class="fa fa-long-arrow-right"> </i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>

        </div>

        <div class="row back-white" id="testimonials">
            <div class="container-fluid testimonials-wrapper">
                <div class="row">
                    <div class="headingBox back-red center-block">
                        <h5 class="text-center text-bold">TESTIMONIALS</h5>
                    </div>
                </div>
            </div>

            <div class="container-fluid container-testimonials">
                <div class="row testi-carousel">
                    <div class="col-md-10 col-md-offset-1">
                        <carousel
                                :items="1"
                                :nav="false"
                                :autoplay="true"
                        >
                            <div class="testimonial">
                                <h3 class="title">
                                    <span class="post">- Dan -</span>
                                </h3>
                                <p class="description">
                                    " ORT has been an incredible force for good working with us at Hopeworks N Camden. "
                                </p>
                            </div>
                            <div class="testimonial">
                                <h3 class="title">
                                    <span class="post">- Vic -</span>
                                </h3>
                                <p class="description">
                                    " Updating our 8 year old legacy application that runs our business went smoother
                                    than anticipated, thanks to James and his team. "
                                </p>
                            </div>
                            <div class="testimonial">
                                <h3 class="title">
                                    <span class="post">- Matt -</span>
                                </h3>
                                <p class="description">
                                    " We appreciate your knowledgable and focused team of developers who assisted us
                                    with the launch of our new campaigns. "
                                </p>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>

        <div class="row contact-fourth back-red">
            <div class="col-sm-3 text-left">
                <a href="#"></a>
            </div>
            <div class="col-sm-6">
                <p></p>
            </div>
            <div class="col-sm-3 text-right">
                <router-link to="/work">
                    <h2 class="heading text-light">Projects <i class="fa fa-long-arrow-right"> </i></h2>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
    import $ from 'jquery';
    import carousel from 'vue-owl-carousel'

    export default {
        name: "Home",
        mounted() {
            window.scrollTo(0, 0);
            this.slider()
        },
        components: {
            carousel
        },
        methods: {
            slider() {
                $(document).ready(function () {
                    var $slider = $(".slider"), $slideBGs = $(".slide__bg"), diff = 0, curSlide = 0,
                        numOfSlides = $(".slide").length - 1, animating = !1, animTime = 500, autoSlideTimeout,
                        autoSlideDelay = 10000, $pagination = $(".slider-pagi");

                    function createBullets() {
                        for (var i = 0; i < numOfSlides + 1; i++) {
                            var $li = $("<li class='slider-pagi__elem'></li>");
                            $li.addClass("slider-pagi__elem-" + i).data("page", i);
                            if (!i)
                                $li.addClass("active");
                            $pagination.append($li)
                        }
                    }

                    createBullets();

                    function manageControls() {
                        $(".slider-control").removeClass("inactive");
                        if (!curSlide)
                            $(".slider-control.left").addClass("inactive");
                        if (curSlide === numOfSlides)
                            $(".slider-control.right").addClass("inactive")
                    }

                    function autoSlide() {
                        autoSlideTimeout = setTimeout(function () {
                            curSlide++;
                            if (curSlide > numOfSlides)
                                curSlide = 0;
                            changeSlides()
                        }, autoSlideDelay)
                    }

                    autoSlide();

                    function changeSlides(instant) {
                        if (!instant) {
                            animating = !0;
                            manageControls();
                            $slider.addClass("animating");
                            $slider.css("top");
                            $(".slide").removeClass("active");
                            $(".slide-" + curSlide).addClass("active");
                            setTimeout(function () {
                                $slider.removeClass("animating");
                                animating = !1
                            }, animTime)
                        }
                        window.clearTimeout(autoSlideTimeout);
                        $(".slider-pagi__elem").removeClass("active");
                        $(".slider-pagi__elem-" + curSlide).addClass("active");
                        $slider.css("transform", "translate3d(" + -curSlide * 100 + "%,0,0)");
                        $slideBGs.css("transform", "translate3d(" + curSlide * 50 + "%,0,0)");
                        diff = 0;
                        autoSlide()
                    }

                    function navigateLeft() {
                        if (animating)
                            return;
                        if (curSlide > 0)
                            curSlide--;
                        changeSlides()
                    }

                    function navigateRight() {
                        if (animating)
                            return;
                        if (curSlide < numOfSlides)
                            curSlide++;
                        changeSlides()
                    }

                    $(document).on("mousedown touchstart", ".slider", function (e) {
                        if (animating)
                            return;
                        window.clearTimeout(autoSlideTimeout);
                        var startX = e.pageX || e.originalEvent.touches[0].pageX
                            , winW = $(window).width();
                        diff       = 0;
                        $(document).on("mousemove touchmove", function (e) {
                            var x = e.pageX || e.originalEvent.touches[0].pageX;
                            diff  = (startX - x) / winW * 70;
                            if ((!curSlide && diff < 0) || (curSlide === numOfSlides && diff > 0))
                                diff /= 2;
                            $slider.css("transform", "translate3d(" + (-curSlide * 100 - diff) + "%,0,0)");
                            $slideBGs.css("transform", "translate3d(" + (curSlide * 50 + diff / 2) + "%,0,0)")
                        })
                    });
                    $(document).on("mouseup touchend", function (e) {
                        $(document).off("mousemove touchmove");
                        if (animating)
                            return;
                        if (!diff) {
                            changeSlides(!0);
                            return
                        }
                        if (diff > -8 && diff < 8) {
                            changeSlides();
                            return
                        }
                        if (diff <= -8) {
                            navigateLeft()
                        }
                        if (diff >= 8) {
                            navigateRight()
                        }
                    });
                    $(document).on("click", ".slider-control", function () {
                        if ($(this).hasClass("left")) {
                            navigateLeft()
                        } else {
                            navigateRight()
                        }
                    });
                    $(document).on("click", ".slider-pagi__elem", function () {
                        curSlide = $(this).data("page");
                        changeSlides()
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .back-image-1 {
        background-image: url('../assets/img/home-bg1.jpg');
    }

    .back-image-2 {
        background-image: url('../assets/img/home-bg3.jpg');
    }

    .testimonial {
        border: 10px solid #0f3256;
        padding: 40px 0 25px 0;
        margin: 50px;
        text-align: center;
        position: relative;
    }

    .testimonial:before {
        content: "\f10e";
        /*font-family: "Font Awesome 5 Free";*/
        width: 100px;
        height: 100px;
        line-height: 100px;
        background: #fff;
        margin: 0 auto;
        font-size: 70px;
        font-weight: 900;
        color: #f1971f;
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
        display: inline-block;
        font: normal normal normal 70px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }

    .testimonial .title {
        padding: 7px 0;
        margin: 0 -30px 20px;
        border: 7px solid #fff;
        background: #df071c;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
    }

    .testimonial .title:before {
        content: "";
        border-top: 15px solid #0f3256;
        border-left: 15px solid transparent;
        border-bottom: 15px solid transparent;
        position: absolute;
        bottom: -37px;
        left: 0;
    }

    .testimonial .title:after {
        content: "";
        border-top: 15px solid #0f3256;
        border-right: 15px solid transparent;
        border-bottom: 15px solid transparent;
        position: absolute;
        bottom: -37px;
        right: 0;
    }

    .testimonial .post {
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        text-transform: capitalize;
    }

    .testimonial .description {
        padding: 0 20px;
        margin: 0;
        font-size: 15px;
        color: #6f6f6f;
        letter-spacing: 1px;
        line-height: 30px;
    }

    .owl-theme .owl-controls {
        margin-top: 0;
    }

    .owl-theme .owl-controls .owl-buttons div {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 35px;
        background: #f1971f;
        color: #fff;
        border-radius: 0;
        margin-right: 5px;
        opacity: 1;
    }

    .owl-prev:before,
    .owl-next:before {
        content: "\f10e";
        font-family: "Font Awesome 5 Free";
        font-size: 20px;
        font-weight: 900;
    }

    .owl-next:before {
        content: "\f061";
    }

    @media only screen and (max-width: 990px) {
        .testimonial {
            margin: 30px;
        }
    }
</style>
